import React from 'react';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import Component from './newcomponent'
import BlockWrapper, {
  MilestoneCard,
  CounterWrapper,
  CounterItem,
} from './milestoneBlock.style';

const MilestoneBlock = () => {
 
  return (
    <Container  width="1260px">
       <BlockWrapper>
      <Component/>
      </BlockWrapper>
    </Container>
  );
};

export default MilestoneBlock;
