import React, { Fragment } from "react"
import firestore from "../../../firestore";
import  firebase from "@firebase/app";
import "@firebase/firestore";
import { Link } from 'gatsby';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import  {
 
    CircleLoader
  } from './milestoneBlock.style';
  const HeadingLink = ({slide}) => (
      <Fragment>
    <Link to={`/blogdetails/${slide.id}`}
      state={{  blogid: slide.id }} >
          
       <Heading content={slide.Title} />
    </Link>
    </Fragment>
  );
  const ReadMore = ({slide}) => (
    <Fragment>
  <Link to={`/blogdetails/${slide.id}`}
      state={{  blogid: slide.id }} className="learn__more-btn">
        <span className="hyphen" />
        <span className="btn_text">Read More</span>
      </Link>
  </Fragment>
)
  
function Component() {
  const [data, setData] = React.useState(null)
  const glideOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
    autoplay:6000
  };
  var cities =[]
  React.useEffect(() => {
      
    firebase.firestore().collection("BlogsDetails").where('ArticleCategory','==','Career-Tips').orderBy("timestamp","asc").limit(3)
    .onSnapshot(function(querySnapshot) {
       
        querySnapshot.forEach(function(doc) {
          var obj = doc.data()
          obj.id = doc.id
            cities.push(obj);
            // console.log(cities)
            setData(
                cities
            );
           
        });
      })
  }, [])

  return <div> {data  ? (
        <GlideCarousel 
            carouselSelector="charitySlide"
            // mountbefore={dating}
            options={glideOptions}
            nextButton={<span className="next_arrow" />}
            prevButton={<span className="prev_arrow" />}
          >
            <Fragment> 
        { data.map((slide,index) => (
    //   <Fragment  key={`blog__post-key${index}`}>     
    <GlideSlide key={`blog__post-key${index}`}>      
      <Image src={slide.BannerImg} alt={slide.Title} />
      {/* <Link to="/blogdetails"
      state={{  blogid: slide.id }} >{slide.id}
      <Heading content={slide.Title} /></Link> */}
      <HeadingLink slide={slide}/>
      <Text content ={`${slide.ArticleCopy.substring(0,300)}...`}></Text>
      <ReadMore slide={slide}/>


   
</GlideSlide>
//    </Fragment>
  )) }
  
  </Fragment>
  </GlideCarousel>
  ):  
//(
//     <CircleLoader>
//       <div className="circle"></div>
//       <div className="circle"></div>
//     </CircleLoader>
//   )
<div></div>
}
  </div>
        
}

export default Component